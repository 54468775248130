import React from 'react';
import { Link } from 'gatsby';
import useAuth from '../../hooks/useAuth';

// import "../../styles/nav.scss";

export default function Navigation() {
    const { logout, isAuthenticated } = useAuth();
    const handleLogout = (e) => {
        e.preventDefault();
        logout();
    };

    return (
        <div uk-sticky='sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky'>
            <nav id='navigation' className='uk-navbar-container uk-navbar'>
                <div className='uk-navbar-left'>
                    <Link to='/#navigation' className='uk-navbar-item uk-logo'>
                        Elowen
                    </Link>
                    <div className='uk-navbar-item'>
                        <form action=''>
                            <input
                                className='uk-input uk-form-width-small'
                                type='text'
                                placeholder='Input'
                            />
                            <button className='uk-button uk-button-default'>
                                Button
                            </button>
                        </form>
                    </div>
                </div>
                <div className='uk-navbar-right'>
                    <ul className='uk-navbar-nav'>
                        <li>
                            <Link
                                to='/shop'
                                activeClassName='uk-active'
                                partiallyActive={true}
                            >
                                Shop
                            </Link>
                        </li>
                        <li>
                            <Link
                                to='/blog'
                                activeClassName='uk-active'
                                partiallyActive={true}
                            >
                                Blog
                            </Link>
                        </li>

                        {isAuthenticated ? (
                            <>
                                <li>
                                    <Link
                                        to='/app/account'
                                        activeClassName='uk-active'
                                        partiallyActive={true}
                                    >
                                        Account
                                    </Link>
                                </li>
                                <li>
                                    <a onClick={handleLogout} href='/'>
                                        Log Out
                                    </a>
                                </li>
                            </>
                        ) : (
                            <li>
                                <Link
                                    to='/login/'
                                    activeClassName='uk-active'
                                    partiallyActive={true}
                                >
                                    Log In
                                </Link>
                            </li>
                        )}
                    </ul>
                </div>
            </nav>
        </div>
    );
}

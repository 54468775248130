import React from 'react';
import { Link } from 'gatsby';
import { Code, Heart } from 'react-feather';

// import "../../styles/footer.scss";

export default function Footer() {
    return (
        <footer>
            <section className=''>
                <div className=''>
                    <p>
                        <Link to='/'>elowen</Link> was <Code /> with <Heart />{' '}
                        by{' '}
                        <a href='https://www.brolgadigital.com.au'>
                            brolga digital
                        </a>
                        .
                    </p>
                </div>
            </section>
        </footer>
    );
}

import React from 'react';
import { Helmet } from 'react-helmet';
import Parallax from '../Parallax';
import Navigation from './Navigation';
import Footer from './Footer';

import 'prismjs/themes/prism.css';
import '../../styles/style.scss';

export default function Layout({ location, children }) {
    const path = location.pathname;

    return (
        <div className='wrapper'>
            <Helmet>
                {/* <!-- UIkit JS --> */}
                <script
                    defer
                    src='https://cdn.jsdelivr.net/npm/uikit@3.11.1/dist/js/uikit.min.js'
                ></script>
                <script
                    defer
                    src='https://cdn.jsdelivr.net/npm/uikit@3.11.1/dist/js/uikit-icons.min.js'
                ></script>
            </Helmet>

            {path === "/" ? <Parallax /> : <></>}
            
            <Navigation />

            <main
                className='uk-container uk-container-large uk-flex uk-flex-between	uk-flex-row uk-flex-wrap uk-flex-top'
                uk-height-viewport='expand: true'
            >
                {children}
            </main>

            <Footer />
        </div>
    );
}

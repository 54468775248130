import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { ArrowDown } from 'react-feather';

import '../styles/parallax.scss';

export default function Parallax() {
    return (
        <div id='parallax'>
            <StaticImage
                className='parallax-element'
                src='../../static/images/header-1.png'
                alt=''
            />
            <StaticImage
                className='parallax-element'
                src='../../static/images/header-2.png'
                alt=''
            />
            <StaticImage
                className='parallax-element'
                src='../../static/images/header-3.png'
                alt=''
            />
            <StaticImage
                className='parallax-element'
                src='../../static/images/header-4.png'
                alt=''
            />
            {/* <h1>{{ config.site.title }}</h1> */}
            <p>
                <a href='#navigation'>Start Exploring</a>
                <ArrowDown />
            </p>
        </div>
    );
}
